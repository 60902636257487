<template>
  <div class="bg">
    <!-- <img class="backImg" src="../../assets/index.jpg" /> -->
    <div :class="oderReady ===  3 || oderReady ===  2 ? 'logiHeight flex' : 'logn flex'">
      <img class="left" src="../../assets/images/logogengxin.png" />
      <div class="loginBox">
        <div class="title flex_jcsb_ac">
          <img :src="greement.logo || '/src/assets/images/logo.png'" alt="" />
          <div class="welcome">Welcome</div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="登录" name="first"></el-tab-pane>
          <el-tab-pane label="注册" name="second"></el-tab-pane>
        </el-tabs>
        <div class="singnin">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
            <el-form-item  prop="phone">
              <el-input @keyup.enter.native="userLogin('ruleForm')" placeholder="请输入内容" v-model="ruleForm.phone" clearable><i slot="prefix" class="el-input__icon el-icon-user"></i></el-input>
            </el-form-item>
            <el-form-item  prop="code" v-if="oderReady === 1 || oderReady === 2 || oderReady === 3">
              <el-input @keyup.enter.native="userLogin('ruleForm')" placeholder="请输入验证码" v-model="ruleForm.code">
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                <div class="code" slot="append" v-if="isCode" @click="getCode">获取验证码</div>
                <div class="iscode" slot="append" v-else>{{times }}s</div>
              </el-input>
            </el-form-item>
            <el-form-item prop="password" v-if="oderReady === 0 || oderReady === 2 || oderReady === 3">
             <el-input @keyup.enter.native="userLogin('ruleForm')" placeholder="请输入密码" v-model="ruleForm.password" show-password><i slot="prefix" class="el-input__icon el-icon-lock"></i></el-input>
            </el-form-item>
            <el-form-item prop="passwordTwo" v-if="oderReady === 2 || oderReady === 3">
              <el-input @keyup.enter.native="userLogin('ruleForm')" placeholder="请确认密码" v-model="ruleForm.passwordTwo" show-password><i slot="prefix" class="el-input__icon el-icon-lock"></i></el-input>
            </el-form-item>
          </el-form>
          <div class="forget flex_jcsb_ac">
            <div class="text" v-if="oderReady === 0 || oderReady === 1" @click="codeClick(2)">忘记密码？</div>
            <div class="text" v-if="oderReady === 0" @click="codeClick(1)">验证码登录</div>
            <div class="text" v-if="oderReady === 1" @click="codeClick(0)">密码登录</div>
          </div>
          <div v-if="oderReady === 3" class="agreement cursor" @click="dialogVisible = true">注册即同意<span>《用户协议》</span></div>
        </div>
        <div class="loginButton">
          <el-button class="width" type="primary" @click="userLogin('ruleForm')">{{oderReady === 3 ? '注册' : oderReady === 2 ? '确定' :'登录'}}</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="《用户协议》"
      :visible.sync="dialogVisible"
      width="60%">
      <span v-html="greement.user_agreement"></span>
    </el-dialog>
  </div>
</template>

<script>
import { login, smsSend, resetPassword, register, apiPhoneLogin } from '@/api/common/login'
import { checkTelphone } from '@/utils/commonMeans'
import {system} from '@/api/common/public'
export default {
  name: 'login',
  data() {
    var checkPhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码'));
      } else if (!checkTelphone(this.ruleForm.phone)) {
        callback(new Error('请输入正确的手机号码'));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        passwordTwo: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'change' }
        ],
        phone: [
          { required: true, validator: checkPhone, trigger: 'change' }
        ]
      },
      ruleForm: {
        phone: '',
        password: '',
        passwordTwo: '',
        code: ''
      },
      activeName: 'first',
      tabsList: ['登录', '注册'],
      oderReady: 0, // 0 密码登录 3 注册 2忘记密码 1验证码登录
      times: 60, // 验证码时间
      isCode: true, // 验证码显示
      greement: {},
      dialogVisible: false
    }
  },
  created() {
    // console.log(localStorage.getItem('loginType'))
    this.systemModel()
    if (localStorage.getItem('loginType') === '1') {
      this.oderReady = 3
      this.activeName = 'second'
    }
  },
  methods: {
    systemModel() {
      const data = {
        key: 'logo,user_agreement'
      }
      system(data).then(res => {
        this.greement = res.data
      })
    },
    getCode() { // 验证码倒数
      this.isCode = false
      this.verificationCde()
      this.timer = setInterval(() => {
        this.times--
        if (this.times === 0) {
          this.isCode = true
          clearInterval(this.timer)
          this.times = 60
        }
      }, 1000)
    },
    verificationCde() { // 获取验证码
      const data = {
        type: this.oderReady === 1 ? 'LOGIN' : this.oderReady === 3 ? 'REGISTER' : this.oderReady === 2 ? 'SETPASSWORD' : 'LOGIN',
        phone: this.ruleForm.phone
      }
      smsSend(data).then(res => {
        this.$message.success('发送成功');
      }).catch(res => {
        // console.log('res', res);
      })
    },
    codeClick(type) { // 验证码登录
      this.oderReady = type
      // console.log(this.oderReady)
    },
    handleClick(tab, event) {
      if (this.activeName === 'first') {
        this.oderReady = 0
      } else {
        this.oderReady = 3
      }
      localStorage.setItem('loginType', this.oderReady)
    },
    login() {
      const user = {
        phone: this.ruleForm.phone,
        password: this.ruleForm.password
      }
      login(user).then(res => {
        if (res.code === 1) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          localStorage.setItem('token', res.data.token);
          this.$message.success('登录成功');
          localStorage.removeItem('loginType')
          this.$router.push('/')
        } else {
          this.$message.success(res.mes);
        }
      }).catch(res => {})
    },
    userLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.oderReady)
          if (this.oderReady === 2) { // 忘记密码登录
            const info = {
              phone: this.ruleForm.phone,
              password: this.ruleForm.password,
              code: this.ruleForm.code
            }
            resetPassword(info).then(res => {
              if (res.code === 1) {
                this.$message.success('修改密码成功');
                this.login()
              }
            })
          } else if (this.oderReady === 1) { // 验证码登录
            const user = {
              phone: this.ruleForm.phone,
              code: this.ruleForm.code
            }
            apiPhoneLogin(user).then(res => {
              if (res.code === 1) {
                this.$message.success('修改密码成功，登录');
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                localStorage.setItem('token', res.data.token);
                localStorage.removeItem('loginType')
                this.$router.push('/')
              }
            }).catch(res => {})
          } else if (this.oderReady === 3) { // 注册 登录
            const data = {
              phone: this.ruleForm.phone,
              password: this.ruleForm.password,
              code: this.ruleForm.code,
              name: this.ruleForm.phone,
              from_app: 'pc'
            }
            register(data).then(res => {
              if (res.code === 1) {
                this.$message.success('注册成功');
                this.login()
              }
            }).catch(res => {
            })
          } else {
            this.login() // 密码登录
          }
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
  /deep/{
    .el-form-item{
      margin-bottom: 0;
    }
    .el-form-item__error{
      top: 46px;
      left: 0;
    }
    .el-input-group__append, .el-input-group__prepend{
      background-color: #fff;
      padding: 0;
    }
    .el-input--prefix .el-input__inner{
      padding-left: 40px;
      height: 48px;
    }
    .el-input{
      margin-bottom: 20px;
    }
    .el-tabs__header{
      width: 400px;
      text-align: center;
      margin: 15px auto 20px;
      .el-tabs__nav-wrap::after{
          height: 4px;
          background-color: #ECF2F8;
        }
      .el-tabs__nav{
        width: 100%;
        .el-tabs__item{
          width: 50%;
          padding: 0;
          margin-bottom: 15px;
          font-size: 24px;
          color: #333333;
        }
        .el-tabs__active-bar{
          width: 50%;
          background-color: #1166CF;
          height: 4px;
        }
      }
    }
  }
  .loginButton{
    text-align: center;
    margin-top: 20px;
    .width{
      width: 400px;
    }
  }
  .singnin{
    width: 400px;
    margin: auto;
    .code{
      width: 110px;
      cursor:pointer;
      text-align: center;
      color: #1166CF;
    }
    .iscode{
      width: 110px;
      text-align: center;
      color: #1166CF;
    }
    .el-icon-user{
      font-size: 24px;
    }
    .el-icon-lock{
      font-size: 24px;
    }
    .agreement{
      text-align: right;
      font-size: 14px;
      span{
        color: #1166CF;
      }
    }
    .forget{
      width: 100%;
      font-size: 16px;
      color: #666666;
      .text{
        cursor:pointer;
      }
      :hover{
        color: #1166CF;
      }
    }
  }
  .bg{
    .logiHeight{
      background: rgba(255,255,255,0.66);
      border-radius: 30px;
      width: 1200px;
      margin: 58px auto;
      position: relative;
      height: 100%;
      flex: 1;
    }
    .logn{
      background: rgba(255,255,255,0.66);
      border-radius: 30px;
      width: 1200px;
      margin: 58px auto;
      position: relative;
      height: 500px;
      flex: 1;
    }
    .left{
      width: 600px;
      height: 500px;
      border-radius: 30px 0 0 30px;
    }
    .loginBox{
      flex: 1;
      padding: 12px 20px 0 20px;
      .welcome{
        font-size: 20px;
      }
    }
    .backImg{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 600px;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
</style>
