import request from '@/utils/request'

export function smsSend(data) { // 1.发送短信
  return request({
    url: 'Sms/send',
    method: 'post',
    data
  })
}
export function login(data) { // 3.密码登录
  return request({
    url: `User/login`,
    method: 'POST',
    data
  })
}
export function resetPassword(data) { // 4.找回密码
  return request({
    url: 'User/setPassWord',
    method: 'POST',
    data
  })
}
export function apiPhoneLogin(data) { // 5.验证码登录
  return request({
    url: 'User/loginCode',
    method: 'POST',
    data
  })
}
export function register(data) { // 2.注册
  return request({
    url: 'User/register',
    method: 'POST',
    data
  })
}
